.diaDisponible{
  background-color: #86C67C;
}

.titulo2{
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-size: 20px;
}
